import { transition } from './utils';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'animate.css';
import '../css/common.css';
import '../css/dashboard.css';

export default function dashboard(account) {
    $('#banner').empty();
    $('#interface').empty();
    
    const final_report = account.tracked.step4['Final report'] > 0 ? '<span class="check-mark">&check;</span>':'<span class="cross-mark">&times;</span>';
    const final_metrics = account.tracked.step4.Metrics > 0 ? '<span class="check-mark">&check;</span>':'<span class="cross-mark">&times;</span>';
    const creative_materials = account.tracked.step3['Creative materials'] > 0 ? '<span class="check-mark">&check;</span>':'<span class="cross-mark">&times;</span>';
    const budget_reconcile = account.tracked.budgetManagement['Refund receipt'] > 0 ? '<span class="check-mark">&check;</span>':'<span class="cross-mark">&times;</span>';
    const photos_videos = account.tracked.step3['Photos and videos'] > 0 ? '<span class="check-mark">&check;</span>':'<span class="cross-mark">&times;</span>';
    const press_hits = account.tracked.step3['Press hits'] > 0 ? '<span class="check-mark">&check;</span>':'<span class="cross-mark">&times;</span>';
    const opposition = account.tracked.step4['Opposition summary'] > 0 ? '<span class="check-mark">&check;</span>':'<span class="cross-mark">&times;</span>';

    $('#interface').html(`<center><button type="button" id="return" class="btn btn-primary w-25">Return</button></center>
        <p class="text-center display-4 pt-5 px-5">Primary Portfolio Requirements</p>
        <div class="row rounded mt-3">
            <div class="col-sm-3 g-0 p-2 card-bg-primary text-light animate__animated animate__backInLeft rounded left">
                <p class="small fw-bold">Final Report</p>
                <p class="text-center lead">${final_report}</p>
            </div>
            <div class="col-sm-3 g-0 mx-1 p-2 card-bg-primary text-light animate__animated animate__backInLeft rounded left">
                <p class="small fw-bold">Final Metrics</p>
                <p class="text-center lead">${final_metrics}</p>
            </div>
        </div>
        <div class="row rounded mt-1">
            <div class="col-sm-3 g-0 p-2 card-bg-primary text-light animate__animated animate__backInLeft rounded left">
                <p class="small fw-bold">Creative Materials</p>
                <p class="text-center lead">${creative_materials}</p>
            </div>
            <div class="col-sm-3 g-0 mx-1 p-2 card-bg-primary text-light animate__animated animate__backInLeft rounded left">
                <p class="small fw-bold">Budget Reconciled</p>
                <p class="text-center lead">${budget_reconcile}</p>
            </div>
        </div>
        <p class="text-center display-4 pt-5 px-5">Secondary Portfolio Components</p>
        <div class="row rounded mt-3">
            <div class="col-sm-2 g-0 p-2 bg-info text-light animate__animated animate__backInLeft rounded left">
                <p class="small fw-bold">Photos & Videos</p>
                <p class="text-center lead">${photos_videos}</p>
            </div>
            <div class="col-sm-2 g-0 mx-1 p-2 bg-info text-light animate__animated animate__backInLeft rounded left">
                <p class="small fw-bold">Press Hits</p>
                <p class="text-center lead">${press_hits}</p>
            </div>
        </div>
    `);
    $('#return').on('click', () => transition(() => window.location.href = '/'));
}