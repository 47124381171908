/**
 * Configuration for the domain of the API
 */
let domain;

if (process.env.ENVIRONMENT === 'production') {
  // In production, use the environment variable
  domain = process.env.APIDOMAIN; 
} else {
  // In development, load from the local file
  const localEnv = require('../../ENV');
  domain = localEnv.domain;
}

/** 
 * Configuration for the schools used in the final report examples
*/
const examplesFinalReport = ['George Washington University','Johns Hopkins University','Xavier University of Louisiana'];

/** 
 * Configuration for index page content in 'Steps' preview on hover
*/
const ignore = new Set(['Project pitch']);
const detail = new Set(['Registrations', 'Funding amount', 'Status report', 'Press hits', 'Spent', 'Receipts']);
const subtitute = new Map([
    [,],
]);

/**
 * Configuration for the mapping of month from Date() to its string equivalent
 */
const month = new Map([
    [0,'January'],
    [1,'February'],
    [2,'March'],
    [3,'April'],
    [4,'May'],
    [5,'June'],
    [6,'July'],
    [7,'August'],
    [8,'September'],
    [9,'October'],
    [10,'November'],
    [11,'December'],
]);

/**
 * Configuration for the dropdown menu of the help request form
 */
const optionsHelp = new Map([
    ['1',['Identifying Action Items','Developing Team Structure','Choosing a Topic']],
    ['2',['How to Conduct Research','Conducting Primary Research','Conducting Secondary Research',
        'Conducting Topical Research','Conducting Target Audience Research','Subject Matter Expertise',
        'Developing a Name or Slogan','Developing Creative Elements','Developing Strategies and Tactics']],
    ['3',['Identifying What Should be Measured','How to Set Benchmarks','Sources and Methods for Data Collection and Analysis',
        'Executing Strategies and Tactics','How to Test and Revise Strategies and Tactics', 'Outreach',
        'Social Media Use','Holding Events','Product or Tool Testing','Data Collection']],
    ['4',['Post-research','Measuring Effectiveness','Developing and Review of a Final Report Draft',
        'Project Wrap-up Reporting on the Program Portal','Final Portfolio Items']],
    ['budget',['Completing Budget Request Form','Allowable Expenses','Recording Expenses and Receipts','Project Manager Approval']]
]);

/** 
 * Program for this instance of the client portal
*/
const Program = 'Invent2Prevent';
const Semester = 'Spring';
const Year = '2024';

/**
 * Configuration for the endpoint of the tracked item
 */
const route = new Map([
    ['login', 'v1/participant/login'],
    ['Registration',`v1/participant/step1/${encodeURIComponent('Registrations')}`],
    ['Kickoff material',`v1/participant/step1/${encodeURIComponent('Kickoff material')}`],
    ['Project brief',`v1/participant/step1/${encodeURIComponent('Project brief')}`],
    ['Pre-survey',`v1/participant/step1/${encodeURIComponent('Pre-survey')}`],
    ['Rules',`v1/participant/step1/${encodeURIComponent('Rules')}`],
    ['Scorecard',`v1/participant/step1/${encodeURIComponent('Scorecard')}`],
    ['Research material',`v1/participant/step2/${encodeURIComponent('Research material')}`],
    ['Status report',`v1/participant/step2/${encodeURIComponent('Status report')}`],
    ['Project pitch',`v1/participant/step2/${encodeURIComponent('Project pitch')}`],
    ['Project check-in',`v1/participant/step2/${encodeURIComponent('Project check-in')}`],
    ['Social media handles',`v1/participant/step3/${encodeURIComponent('Social media handles')}`],
    ['Creative materials',`v1/participant/step3/${encodeURIComponent('Creative materials')}`],
    ['Photos and videos',`v1/participant/step3/${encodeURIComponent('Photos and videos')}`],
    ['Press hits',`v1/participant/step3/${encodeURIComponent('Press hits')}`],
    ['Metrics',`v1/participant/step4/${encodeURIComponent('Metrics')}`],
    ['Final report',`v1/participant/step4/${encodeURIComponent('Final report')}`],
    ['Post-program survey',`v1/participant/step4/${encodeURIComponent('Post-program survey')}`],
    ['Budget request form',`v1/participant/budgetManagement/${encodeURIComponent('Budget request form')}`],
    ['Refund',`v1/participant/budgetManagement/${encodeURIComponent('Refund')}`],
    ['Spent',`v1/participant/budgetManagement/${encodeURIComponent('Spent')}`],
    ['Guide to step 2','v1/participant/step2/Guide'],
    ['Guide to step 3','v1/participant/step3/Guide'],
    ['Guide to step 4','v1/participant/step4/Guide'],
    ['Guide to budget management','v1/participant/budgetManagement/Guide'],
]);

/**
 * Configuration for the location of the tracked item in the object
 */
const step = new Map([
    ['Registration','step1'],
    ['Kickoff material','step1'],
    ['Project brief','step1'],
    ['Pre-survey','step1'],
    ['Rules','step1'],
    ['Scorecard','step1'],
    ['Research material','step2'],
    ['Status report','step2'],
    ['Project pitch','step2'],
    ['Project check-in','step2'],
    ['Guide to step 2','step2'],
    ['Social media handles','step3'],
    ['Creative materials','step3'],
    ['Photos and videos','step3'],
    ['Press hits','step3'],
    ['Guide to step 3','step3'],
    ['Metrics','step4'],
    ['Final report','step4'],
    ['Post-program survey','step4'],
    ['Guide to step 4','step4'],
    ['Budget request form','budgetManagement'],
    ['Refund','budgetManagement'],
    ['Spent','budgetManagement'],
    ['Guide to budget management','budgetManagement']
]);

export {
    detail,
    domain,
    examplesFinalReport,
    ignore,
    month,
    optionsHelp,
    route,
    step,
    Semester,
    Year,
    Program
}